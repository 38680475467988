import {
  useEffect,
  useState
} from "react"

import axios from "axios"

// const API_URL = "https://insights.touchcore.com.ng/?res_route=/wp/v2"
const API_URL = "https://insights.touchcore.com.ng?rest_route=/wp/v2"
const categories = [
  {
    id: 1,
    name: "Uncategorized",
    slug: "uncategorized",
  },

  {
    id: 2,
    name: "Web Design",
    slug: "web-design",
  },
  {
    id: 4,
    name: "Branding",
    slug: "branding",
  },
  {
    id: 9,
    name: "Business Development",
    slug: "business-development",
  },
  {
    id: 12,
    name: "Digital Marketing",
    slug: "digital-marketing",
  },
  {
    id: 19,
    name: "Graphics Design",
    slug: "graphics-design",
  },
  {
    id: 27,
    name: "Jobs",
    slug: "jobs",
  },

  {
    id: 20,
    name: "Web Development",
    slug: "web-development",
  },
  {
    id: 28,
    name: "User Experience",
    slug: "user-experience",
  },
  {
    id: 29,
    name: "Product Strategy",
    slug: "product-strategy",
  },
  {
    id: 30,
    name: "Product Design",
    slug: "product-design",
  },
  {
    id: 31,
    name: "Mobile App Development",
    slug: "mobile-app-development",
  },
  {
    id: 32,
    name: "Web Audit Services",
    slug: "web-audit-service",
  },
  {
    id: 33,
    name: "Enterprise Solutions",
    slug: "enterprise-solutions",
  },
  {
    id: 34,
    name: "Ecommerce Solutions",
    slug: "ecommerce-solutions",
  },
]
function fetchLatestPost(limit = 6, offset = 0) {
  return axios.get(
    `${API_URL}/posts&_embed&per_page=${limit}&orderby=date&offset=${
      offset * limit
    }`
  )
}
function fetchLatestPostByCategory(limit = 3, category) {
  return axios.get(
    `${API_URL}/posts&_embed&per_page=${limit}&orderby=date&${
      category ? "categories=" + category : ""
    }`
  )
}
export function retrieveAPost(id) {
  return axios.get(`${API_URL}/posts/${id}&_embed`)
}

function postImage(post) {
  if (post.id) {
    return post._embedded["wp:featuredmedia"]
      ? post._embedded["wp:featuredmedia"]["0"].source_url
      : ""
  }
  return ""
}
export function usePost({
  type = "latest",
  categorySlug,
  limit,
  id,
  offset = 0,
}) {
  const [posts, setPosts] = useState([])
  const [singlePost, setSinglePost] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  useEffect(() => {
    async function fetchPost() {
      setError("")
      setLoading(true)
      try {
        let res
        if (type === "latest") {
          res = await fetchLatestPost(limit, offset)
        }
        if (type === "latest-category") {
          const cat = categories.find(c => c.slug === categorySlug)
          res = await fetchLatestPostByCategory(3, cat && cat.id)
        }
        if (type === "single") {
          res = await retrieveAPost(id)
        }
        if (res && res.data && type !== "single") {
          setPosts(res.data)
        } else if (res && res.data && type === "single") {
          setSinglePost(res.data)
        }
      } catch (err) {
        if (err.response) {
          console.log(err.response)
        }
      } finally {
        setLoading(false)
      }
    }
    fetchPost()
  }, [type, categorySlug, id, limit, offset])

  return { posts, loading, error, image: postImage, single: singlePost }
}
