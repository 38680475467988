import "../styles/styles.css"
import "../styles/tailwind.css"
import "aos/dist/aos.css"

import React, {useEffect, useState} from "react"

import Aos from "aos"
import {Link} from "gatsby"

import {Header} from "../components/header2"
import OurProjects from "../components/our-projects"
import SEO from "../components/seo"
import {OurInsights} from "../components/tc-insight2"
import {sendmail} from "../components/send_us_request";

const IndexPage = () => {
    useEffect(() => {
        console.log("initializing AOS")
        Aos.init({
            duration: 1200,
            offset: 200,
            delay: 270,
        })
    }, [])
    return (
        <div className="max-w-full overflow-x-hidden">
            <SEO
                title="Software Development Company | Touchcore Technology Nigeria"
                description={
                    "Touchcore is a full-service software development company of engineers, designers, and developers. Mobile app development Website design and custom software solutions in Lagos, Nigeria"
                }
            />
            <Header/>
            <HeroSection/>
            <Features/>
            <OurProjects/>
            <LittleStone/>
            <OurInsights/>
            <Counter/>
            <Reviews/>
            <SendUsMessage/>
            <Partners/>
            <Footer/>
        </div>
    )
}
export default IndexPage

const HeroSection = () => (
    <section
        id="hero"
        className="home-hero relative flex flex-col h-screen w-full justify-center items-center"
    >
        {/* <div className="bg-black absolute top-0 h-full w-full bg-opacity-75 z-0" /> */}
        <div className="relative z-10 flex flex-col items-center mx-5 mt-40 md:mt-20 md:mx-12">
            <h1
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="700"
                className="text-center text-white leading-tight"
            >
        <span className=" text-2xl md:text-5xl font-medium">
          We build solutions from
        </span>
                <br/>
                <span className="text-2xl md:text-6xl font-black italic">
          inception to delivery
        </span>
            </h1>
            <div data-aos="fade-up" data-aos-delay="1400" data-aos-duration="1000">
                <Link
                    to="/custom-development"
                    className="bg-red-600 flex items-baseline mt-10 text-base mx-auto text-white py-2 px-8"
                >
                    Learn more <span className="arrow-style">&rarr;</span>
                </Link>
            </div>
        </div>
    </section>
)

const features = [
    {
        index: 0,
        image: require("./../assets/user-experience.webp"),
        alt_image: require("./../assets/Oval-3.png"),
        title: "User Experience Design",
        shadow: true,
        link: "/ui-ux",
        description:
            "We are Different, by design. At Touchcore, unbiased research, keen, insight, data, and analysis inform our user experience and design decisions",
    },
    {
        index: 1,
        image: require("./../assets/software-development.webp"),
        alt_image: require("./../assets/Oval-2.png"),
        title: "Software Development",
        border: true,
        link: "/custom-development",
        description:
            "Your application is more than the code, we know that. Understanding your business objectives and striving to provide end-users with the best experience are of utmost importance to our team.",
    },
    {
        index: 2,
        image: require("./../assets/mobile-app-development.webp"),
        alt_image: require("./../assets/Oval-1.png"),
        title: "Mobile App Development",
        border: true,
        link: "/custom-development",
        description:
            "We build beautiful yet powerful iOS / Android applications that solve everyday business problems",
    },
    {
        index: 3,
        image: require("./../assets/website-development.webp"),
        alt_image: require("./../assets/Oval.png"),
        title: "Website Development",
        border: true,
        link: "/web-design",
        description:
            "We build websites that are clean, clear and customized to create lasting impressions both visually and interactively.",
    },
]

const Card = ({
                  shadow = false,
                  border = false,
                  image = "",
                  alt_image,
                  title = "",
                  description = "",
                  link = "",
                  index,
              }) => (
    <div
        data-aos={index < 2 ? "fade-right" : "fade-left"}
        data-aos-delay={200 + 100 * index}
        className=""
    >
        <Link
            to={link}
            className={`h-full flex flex-col bg-white text-center  px-10 ${
                border ? "border border-k3-gray" : ""
            } ${shadow ? "shadow-xl hover:shadow-sm" : ""}`}
        >
            <picture>
                <source srcset={image} type="image/webp"/>
                <source srcset={alt_image} type="image/png"/>
                <img
                    alt={title}
                    src={alt_image}
                    className="rounded-full mt-12 mb-10 mx-auto w-40 h-auto"
                />
            </picture>

            <h6 className="font-semibold text-lg text-k2-gray mb-5">{title}</h6>
            <p className="text-xs text-k-gray mb-12">{description}</p>
        </Link>
    </div>
)

const Features = () => (
    <div className="my-5 md:my-20 container">
        <div className="grid lg:grid-cols-3 gap-8 mb-8">
            <Card {...features[0]} />
            <Card {...features[1]} />
        </div>
        <div className="grid lg:grid-cols-3 gap-8">
            <div className="w-full h-full relative">
                <div
                    style={{zIndex: "-1"}}
                    className="absolute bottom-0 transform rotate-45 -ml-6 -mb-12"
                >
                    <div className="h-16 w-16 rounded-full bg-pred"/>
                    <div className="h-40 w-40 mt-6 rounded-full bg-pred"/>
                </div>
            </div>
            <Card {...features[2]} />
            <Card {...features[3]} />
        </div>
        <div className="text-center my-8 flex justify-center">
            <Link
                to="/what-we-do"
                className="hover:bg-gray-100 flex items-baseline mt-10 text-base text-pred py-2 px-4"
            >
                View more <span className="arrow-style pl-5">&rarr;</span>
            </Link>
        </div>
    </div>
)

function LittleStone() {
    return (
        <div className="relative bg-black little-stone-bg h-screen-fit flex items-end w-full">
            <div className=" container relative flex px-5 md:px-10 items-center z-10 w-full">
                <div
                    data-aos="fade-right"
                    className="relative z-10 py-10 md:py-24 md:w-1/2 lg:w-5/12 text-white"
                >
                    <h5 className="leading-tight text-center md:text-left">
            <span className="text-xl md:text-4xl font-medium">
              Little stone.
            </span>{" "}
                        <br className="hidden md:block"/>
                        <span className="text-xl md:text-5xl font-black">Big Splash</span>
                    </h5>
                    <h6 className="mt-5  text-base md:text-lg text-center md:text-left mb-1">
                        <span className="hidden md:inline">That's the</span>{" "}
                        <span className="capitalize md:normal-case mb-3 md:mb-0">
              simplest
            </span>{" "}
                        way to describe Touchcore.
                    </h6>
                    <p className="text-justify md:text-left mb-6">
                        We’re a firm with diverse life experiences — but the same passion
                        for designing and developing state-of-the-art software and web
                        applications.
                    </p>
                    <div className="flex justify-center  md:justify-start">
                        <Link
                            to="/why-touchcore"
                            className="md:border px-4 py-2 flex items-baseline"
                        >
                            Know More{" "}
                            <span className="arrow-style text-pred md:text-white">
                &rarr;
              </span>
                        </Link>
                    </div>
                </div>
                <div data-aos="zoom-in" className="flex-1">
                    <img
                        className="mx-auto h-48"
                        src={require("./../assets/Play_Button.svg")}
                    />
                </div>
            </div>
        </div>
    )
}

function Counter() {
    return (
        <div className="container mt-20">
            <div
                className="bg-pred border-t-xl px-10 flex text-xs md:text-sm justify-around items-center text-white py-8 md:mx-20 ">
                <div className="flex flex-col items-center">
                    <span className="text-4xl md:text-6xl mb-3">124</span>
                    <span className="">COMPLETE</span>
                    <span>PROJECTS</span>
                </div>
                <span className="w-1 bg-white h-32 rounded-full"/>
                <div className="flex flex-col items-center">
                    <span className="text-4xl md:text-6xl mb-3">99%</span>
                    <span className="">SATISFIED</span>
                    <span>CLIENTS</span>
                </div>
                <span className="w-1 bg-white h-32 rounded-full"/>
                <div className="flex flex-col items-center">
                    <span className="text-4xl md:text-6xl mb-3">235</span>
                    <span className="">SUCCESS</span>
                    <span>CAMPAIGN</span>
                </div>
            </div>
        </div>
    )
}

function Reviews() {
    return (
        <div className="bg-k4-gray">
            <div className="container px-6 py-12 md:p-16">
                <img src={require("./../assets/Quote.svg")} alt="" className=""/>
                <div className="flex mt-16 mb-5">
                    {[1, 2, 3, 4, 5].map((v, i) => (
                        <img
                            key={"star" + i}
                            src={require(v === 5
                                ? "./../assets/star-gray.svg"
                                : "./../assets/star-red.svg")}
                            alt="star"
                        />
                    ))}
                </div>
                <p className="text-xl font-medium text-k2-gray">
                    “Having worked with several agencies over the past ten years, my
                    experience with Touchcore was by far the best.”
                </p>
                <div className="flex space-x-5 mt-16">
                    <div className="flex flex-col items-center">
                        <div className="h-16 w-16 bg-gray-400 border-6 border-pred rounded-full"/>
                        <span className="text-k2-gray text-xl mt-2 text-semibold">
              Olive Anu
            </span>
                        <span className="text-k5-gray text-xs">HEAD OF PRODUCT</span>
                    </div>
                    <div>
                        <div className="h-16 w-16 bg-gray-400 border-6 border-transparent rounded-full"/>
                    </div>
                    <div>
                        <div className="h-16 w-16 bg-gray-400 border-6 border-transparent rounded-full"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function SendUsMessage() {
    const [loading, showLoading] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const submit = async (e) => {
        console.log('here')
        e.preventDefault()
        setSuccess(false)
        setError(false)
        try {
            showLoading(true)
            const name = document.querySelector('input[name="name"]');
            const email = document.querySelector('input[name="email"]');
            const phone = document.querySelector('input[name="phone_no"]') ;
            const message = document.querySelector('textarea[name="message"]');
            const response = await sendmail(name.value, email.value, phone.value, message.value)
            if (response.data) {
                console.log(response.data)
                setSuccess(true)
                name.value = ''
                email.value = ''
                phone.value = ''
                message.value = ''
            }
        } catch (err) {
            if (err.response) {
                console.log(err.response.data)
            }
            setError(true)
        } finally {
            showLoading(false)
        }
    }
    return (
        <div id="send-request" className="container mb-20">
            <div className="bg-pred border-b-xl px-10 text-white pb-8 pt-16 md:mx-20">
                <h5 className="text-3xl ">Start a New Project</h5>

                {success && <p className={"text-green-500 text-base"}>Message sent successfully</p>}
                {error && <p className={"text-red-500 text-base"}>An error occurred</p>}

                <form onSubmit={submit}>
                    <div className="md:flex md:space-x-4">
                        <input
                            className="w-full md:w-1/2 h-12 mb-5 p-3 bg-white bg-opacity-25 placeholder-white font-medium rounded-sm"
                            placeholder="Full Name" name="name" required
                        />
                        <input
                            className="w-full md:w-1/2 h-12 mb-5 p-3 bg-white bg-opacity-25 placeholder-white font-medium rounded-sm"
                            placeholder="Phone" name={"phone_no"} required
                        />
                    </div>
                    <input
                        className="w-full h-12 mb-5 p-3 bg-white bg-opacity-25 placeholder-white font-medium rounded-sm"
                        placeholder="Email Address" name={"email"} required
                    />
                    <textarea
                        className="w-full mb-5 p-3 bg-white bg-opacity-25 placeholder-white font-medium rounded-sm"
                        rows="6" required
                        placeholder="Drop us a message" name={"message"}
                    />
                    {loading ? <div className="loader"/> :
                        <button type="submit" className="bg-white px-12 text-lg rounded-sm text-black py-2">
                            Submit
                        </button>}
                </form>
            </div>
        </div>
    )
}

export function Partners() {
    return (
        <div className="bg-k-black">
            <div className="container flex items-center space-x-8 p-8 overflow-x-hidden">
                <div className="flex-1">
                    <img
                        src={require("./../assets/clients-group2.svg")}
                        className="w-full"
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}

export function Footer() {
    return (
        <div className="bg-k2-black">
            <div className="container px-10 py-20 md:flex justify-between space-x-5 text-white">
                <div className="w-72">
                    <div className="h-12 mb-5">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="239.216"
                            height="63.556"
                            viewBox="0 0 239.216 63.556"
                        >
                            <g
                                id="Group_561"
                                data-name="Group 561"
                                transform="translate(-5052.885 -10550.782)"
                            >
                                <g
                                    id="Group_530"
                                    data-name="Group 530"
                                    transform="translate(1968.102 20840.094)"
                                >
                                    <g
                                        id="Group_574"
                                        data-name="Group 574"
                                        transform="translate(11 6.084)"
                                    >
                                        <g
                                            id="Group_10"
                                            data-name="Group 10"
                                            transform="translate(3066.844 -10284.357) rotate(-15.009)"
                                        >
                                            <path
                                                id="Ellipse_1"
                                                data-name="Ellipse 1"
                                                d="M25.35,0C38.457,0,49.082,7.586,49.082,16.944S38.457,33.888,25.35,33.888,0,27.342,0,17.984,12.243,0,25.35,0Z"
                                                transform="translate(0 34.706) rotate(-45)"
                                                fill="none"
                                                stroke="#fff"
                                                strokeWidth="3"
                                            />
                                            <g
                                                id="Group_9"
                                                data-name="Group 9"
                                                transform="translate(15.588 9.527)"
                                            >
                                                <path
                                                    id="Path_1"
                                                    data-name="Path 1"
                                                    d="M1.673,1.4,29.1.011a1.3,1.3,0,0,1,1.073.364,1.5,1.5,0,0,1,.447,1.1c-.1.913.259,1.648-.6,1.67L.161,4.175c-.39.051.578.241.287-.04A1.5,1.5,0,0,1,0,3.03,1.775,1.775,0,0,1,1.673,1.4Z"
                                                    transform="translate(7.825 0) rotate(23.003)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    id="Path_2"
                                                    data-name="Path 2"
                                                    d="M1.789.629,14.844.011a1.451,1.451,0,0,1,1.626,1.47,1.845,1.845,0,0,1-1.788,1.632L1.626,3.731A1.451,1.451,0,0,1,0,2.261,1.845,1.845,0,0,1,1.789.629Z"
                                                    transform="translate(2.548 4.596) rotate(23.003)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    id="Path_3"
                                                    data-name="Path 3"
                                                    d="M1.692.677,14.98.011a1.325,1.325,0,0,1,1.085.364,1.491,1.491,0,0,1,.452,1.1,1.786,1.786,0,0,1-1.691,1.633L1.538,3.778A1.325,1.325,0,0,1,.452,3.414,1.491,1.491,0,0,1,0,2.308,1.786,1.786,0,0,1,1.692.677Z"
                                                    transform="translate(20.037 11.011) rotate(23.003)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    id="Path_4"
                                                    data-name="Path 4"
                                                    d="M1.562.171,4.976,0,3.414,31.494,0,31.665Z"
                                                    transform="translate(12.381 10.8) rotate(23.003)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    id="Path_5"
                                                    data-name="Path 5"
                                                    d="M1.443.174,4.857,0,3.414,29.738,0,29.91Z"
                                                    transform="translate(17.7 12.772) rotate(23.003)"
                                                    fill="#fff"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                    <text
                                        id="Touchcore"
                                        transform="translate(3150 -10241)"
                                        fill="#fff"
                                        fontSize="36"
                                        fontFamily="CircularStd-Book, Circular Std, Avenir"
                                    >
                                        <tspan x="0" y="0">
                                            Touch
                                        </tspan>
                                        <tspan
                                            y="0"
                                            fontFamily="CircularStd-Black, Circular Std, Avenir"
                                            fontWeight="800"
                                        >
                                            core
                                        </tspan>
                                    </text>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p className="text-xs">
                        Our goal then and now is to help clients streamline their processes
                        in order to meet their challenges.
                    </p>
                    <p className="flex mt-16 font-semibold items-center">
                        <img
                            src={require("./../assets/mail-24px.svg")}
                            className="w-5 mr-2"
                            alt=""
                        />
                        <span>hello@touchcore.com.ng</span>
                    </p>
                    <p className="flex mt-4 font-semibold items-center">
                        <img
                            alt=""
                            src={require("./../assets/call-24px.svg")}
                            className="w-5 mr-2"
                        />
                        <span>08170000560</span>
                    </p>
                </div>
                <div>
                    <h6 className="mt-8 md:mt-0 mb-5 text-base font-bold">Service</h6>
                    <ul className="text-xs space-y-2">
                        <li>
                            <Link to={"/product-strategy"}>Product Strategy</Link>
                        </li>
                        <li>
                            <Link to={"/product-design"}>Product Design</Link>
                        </li>
                        <li>
                            <Link to={"/mobile-application-development"}>
                                Mobile App Development
                            </Link>
                        </li>
                        <li>Web App Development</li>
                        <li>
                            <Link to={"web-design"}>Web Design</Link>
                        </li>
                        <li>
                            <Link to={"/custom-development"}>Custom App Development</Link>
                        </li>
                        <li>
                            <Link to={"/enterprise-solutions"}>Enterprise Solutions</Link>
                        </li>
                        <li>
                            <Link to={"/ui-ux"}>UI / UX</Link>
                        </li>
                        <li>
                            <Link to={"/ecommerce-solutions"}>E-commerce</Link>
                        </li>
                        <li>
                            <Link to={"digital-marketing"}>Digital Marketing</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h6 className="mt-8 md:mt-0 mb-5 text-base font-bold">Company</h6>

                    <ul className="text-xs space-y-2">
                        <li>
                            <Link to={"/our-company"}>About Touchcore</Link>
                        </li>
                        <li>
                            <Link to={"/why-touchcore"}>Why Touchcore</Link>
                        </li>
                        <li>
                            <Link to={"our-solutions"}>Our Works</Link>
                        </li>
                        <li>
                            <Link to={"/#clients"}>Clients</Link>
                        </li>
                        <li>Careers</li>
                        <li>
                            <Link to={"/our-insights"}>Blog</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h6 className="mt-8 md:mt-0 mb-5 text-base font-bold">Address</h6>
                    <ul className="text-xs">
                        <li>14b Wole Ariyo St.</li>
                        <li>Lekki Phase 1 - Lagos</li>
                    </ul>
                    <div className="flex space-x-5 mt-12">
                        <div className="bg-pred flex justify-center items-center h-10 w-10 rounded-full">
                            <img
                                className="h-4"
                                alt=""
                                src={require("./../assets/facebook-white.svg")}
                            />
                        </div>
                        <div className="border flex justify-center items-center h-10 w-10 rounded-full">
                            <img
                                className="h-4"
                                alt=""
                                src={require("./../assets/google.svg")}
                            />
                        </div>
                        <div className="border flex justify-center items-center h-10 w-10 rounded-full">
                            <img
                                alt=""
                                className="h-4"
                                src={require("./../assets/twitter-white.svg")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
