import React, {
  useEffect,
  useRef,
  useState
} from "react"

import { fetchProjects } from "../utils/projects"

const types = [
  { name: "All", tag: "all" },
  { name: "Websites", tag: "web" },
  { name: "App development", tag: "app" },
  { name: "Enterprise solutions", tag: "enterprise" },
  { name: "Fintech", tag: "fintech" },
]

export default function OurProjects() {
  const [active, setActive] = useState("all")
  const [allP, setAllP] = useState([])
  const [projects, setProjects] = useState([])
  const [current, setCurrent] = useState(0)
  const imgSliderRef = useRef()
  const animateToPosition = pos => {
    imgSliderRef.current.style.transform = `translateY(-${pos * 100}%)`
  }
  useEffect(() => {
    const duration = 3000
    const s = setInterval(() => {
      if (imgSliderRef.current) {
        // setLast(current)
        let next = current + 1
        if (next >= projects.length) {
          next = 0
        }
        setCurrent(next)
        animateToPosition(next)
      }
    }, duration)

    return () => {
      clearInterval(s)
    }
  }, [imgSliderRef, current, projects])
  useEffect(() => {
    setCurrent(0)
    animateToPosition(0)
    setProjects(allP.filter(v => v.Tags.includes(active)))
  }, [active, allP])
  useEffect(() => {
    fetchProjects().then(data => {
      setAllP(data.map(d => d.fields))
    })
  }, [])
  console.log(allP)
  return (
    <div className="container pt-5 pb-20 md:py-20">
      <div className="flex justify-end container mb-12 mr-3 px-4  md:px-10">
        <span className="w-20  md:w-32 bg-pred rounded-full h-1 mt-3 mr-2"></span>
        <h2 className="font-bold md:font-medium text-k2-gray text-lg md:text-4xl ">
          Our Projects
        </h2>
      </div>
      <div className="md:flex justify-end">
        <div className="md:w-3/12 mx-5">
          <ul className="uppercase flex overflow-x-auto w-full items-end md:block text-xs text-k3-gray">
            {types.map(t => (
              <li
                key={t.tag}
                className={`project__item pl-6 mb-3 md:mb-0 border-b-4 md:border-b-0 md:border-l-4 py-4 cursor-pointer ${
                  active === t.tag
                    ? "project__item_active border-pred text-black"
                    : ""
                }`}
                onClick={() => setActive(t.tag)}
              >
                {t.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-9/12 lg:w-7/12 flex-shrink-0 mt-3 md:-mt-4 px-10">
          <div style={{ height: "65vh" }} className="w-full  overflow-y-hidden">
            <div
              ref={imgSliderRef}
              className="w-full h-full transition-transform ease-in-out duration-700"
            >
              {!projects.length && (
                <div className="h-full w-full flex justify-center items-center">
                  <p>No projects here</p>
                </div>
              )}
              {projects.map((pr, i) => (
                <div className="h-full w-full flex justify-center items-center">
                  <img
                    key={"projects" + i}
                    src={pr.Image[0].url}
                    alt={pr.Name}
                    className="block object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center space-x-3 mt-4">
            {projects.map((pr, i) => (
              <span
                key={"prjects-tab" + i}
                className={`inline-block transition-colors duration-700  h-5 w-5 box-border rounded-full p-2 border-4 ${
                  i === current ? "bg-pred" : "bg-white"
                }`}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
