import React, {
  useEffect,
  useMemo,
  useState
} from "react"

import { Link } from "gatsby"

const dropDownData = [
  {
    tag: "services",
    image: require("./../assets/ezgif.com-gif-maker (3).webp"),
    title: "Services",
    subtitle:
      "Leverage our over 5 years expertise in web and mobile app design, engineering and deployment to build the next big thing",
    split: true,
    items: [
      { name: "Product Strategy", link: "/product-strategy" },
      { name: "Product Design", link: "/product-design" },
      { name: "Mobile App Development", link: "/mobile-app-development" },
      { name: "Web App Development", link: "" },
      { name: "Web Design", link: "/web-design" },
      { name: "Custom App Development", link: "/custom-development" },
      { name: "Enterprise Solutions", link: "/enterprise-solutions" },
      { name: "UI / UX", link: "/ui-ux" },
      { name: "E-commerce", link: "/e-commerce-solutions" },
      { name: "Digital Marketing", link: "digital-marketing" },
    ],
  },
  {
    tag: "clients",
    image: require("./../assets/ezgif.com-gif-maker (1).webp"),
    title: "Clients",
    subtitle:
      "World-class brands and established companies have chosen Touchcore as a reliable partner.",
    items: [
      { name: "Our Clients", link: "/clients" },
      { name: "Case Studies" },
    ],
  },
  {
    tag: "company",
    image: require("./../assets/ezgif.com-gif-maker (2).webp"),
    title: "Company",
    subtitle:
      "We help companies build solutions to their every day challenges.",
    items: [
      { name: "About Us", link: "/our-company" },
      { name: "Why Touchcore", link: "/why-touchcore" },
      { name: "Our Team", link: "/our-company#team" },
      { name: "Careers" },
      { name: "Insights", link: "/our-insights" },
    ],
  },
  {
    tag: "solutions",
    image: require("./../assets/ezgif.com-gif-maker.webp"),
    title: "Solutions",
    subtitle:
      "We are trusted by the very finest and reputable organizations for developing and deploying industry standard applications",
    split: true,

    items: [
      { name: "Loan Management", link: "/loan-management" },
      { name: "Real Estate Management", link: "/real-estate-management" },
      { name: "Law Firm Management", link: "/law-management" },
      { name: "Travel Agency Management" },
      { name: "Hospital Management", link: "/hospital-management" },
      { name: "Live Streaming", link: "/live-streaming" },
      { name: "Customer Management", link: "/customer-management" },
      { name: "Human Resources Management", link: "/hr-management" },
      { name: "Procurement Management", link: "/procurement-management" },
    ],
  },
]
export const Header = ({ type = 1 }) => {
  const [nav, setNav] = useState(false)
  const [changeNavBG, setChangeNavBG] = useState(false)
  const [navAtv, setNavAtv] = useState("")
  const atv = useMemo(() => dropDownData.find(ddd => ddd.tag === navAtv))
  useEffect(() => {
    const hero = document.querySelector("#hero")
    if (hero) {
      const heroHeight = hero.clientHeight
      window.addEventListener("scroll", e => {
        if (window.scrollY > heroHeight) {
          setChangeNavBG(true)
        } else if (window.scrollY < heroHeight) {
          setChangeNavBG(false)
        }
      })
    } else {
      setChangeNavBG(true)
    }
  }, [])
  const navLinks = [
    { title: "services", link: "/what-we-do" },
    // { title: "clients", link: "/clients" },
    { title: "solutions", link: "/our-solutions" },
    { title: "company", link: "/our-company" },
  ]
  return (
    <header
      className={`fixed w-full z-30 ${
        nav || changeNavBG ? "text-black" : "text-white"
      } ${nav || changeNavBG ? "bg-white" : "bg-transparent"} top-0 shadow  `}
    >
      <div className="container flex whitespace-no-wrap items-center px-4 md:px-10  py-4 h-16">
        <Link to={"/"}>
          <img
            alt="touchcore"
            src={
              nav || changeNavBG
                ? require("./../assets/Touchcore Logo.svg")
                : require("../assets/Touchcore-logo-home1.svg")
            }
            className="h-12"
          />
        </Link>
        <nav
          className={
            (nav
              ? "visible flex flex-col justify-between p-5 md:p-0 items-stretch text-base md:text-sm h-screen-fit w-full md:w-auto "
              : "invisible md:visible w-full h-0 md:h-auto md:w-auto overflow-y-hidden") +
            " transition-all duration-300 mt-16 md:mt-0 absolute z-50 top-0 left-0 bg-white md:bg-transparent md:text-xs shadow-2xl md:shadow-none  md:static ml-auto md:flex md:flex-row md:items-center "
          }
        >
          <div className="hidden md:flex md:flex-row md:items-center">
            {navLinks.map(link => (
              <Link
                className={`mr-8 font-medium ${
                  navAtv === link.title ? "b-active" : ""
                }`}
                onMouseEnter={() => setNavAtv(link.title)}
                to={link.link}
              >
                {link.title.toUpperCase()}
              </Link>
            ))}
          </div>
          <div className="md:hidden">
            {!navAtv && (
              <ul className="grid grid-cols-1 gap-5">
                {navLinks.map(link => (
                  <li
                    className={` font-medium flex justify-between items-center`}
                    onClick={() => setNavAtv(link.title)}
                  >
                    <span>{link.title.toUpperCase()}</span>
                    <span className="text-3xl">&rsaquo;</span>
                  </li>
                ))}
              </ul>
            )}
            {!!navAtv && (
              <div className="">
                <button
                  onClick={() => setNavAtv("")}
                  className="flex items-center uppercase"
                >
                  <span className="text-3xl flex items-center text-pred  mr-2">
                    &lsaquo;
                  </span>
                  <span className="text-base flex items-center font-semibold text-k5-gray">
                    {navAtv}
                  </span>
                </button>
                <ul
                  className={`grid ml-8 mt-5 row-gap-3 grid-cols-1 whitespace-no-wrap"
                  }`}
                >
                  <li className="capitalize font-semibold text-lg mb-2">
                    <Link to={navLinks.find(n => n.title === navAtv)?.link}>
                      {navAtv}
                    </Link>
                  </li>
                  {atv.items.map(it => (
                    <li>
                      <Link
                        onClick={() => setNav(false)}
                        className="cursor-pointer white text-base"
                        to={it.link || "/"}
                      >
                        {it.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="md:hidden">
            {!navAtv && (
              <ul className="text-base my-2 space-y-4 font-black">
                <a className="flex items-center " href="tel:08170000560">
                  <img
                    className="h-6 mr-2"
                    src={require("../assets/phone (1).svg")}
                    alt=""
                  />
                  <span>08170000560</span>
                </a>
                <a
                  className="flex items-center "
                  href="mail-to:hello@touchcore.com.ng"
                >
                  <img
                    className="h-6 mr-2"
                    src={require("../assets/email (1).svg")}
                    alt=""
                  />
                  <span>hello@touchcore.com.ng</span>
                </a>
              </ul>
            )}
          </div>
          <div className="mb-20 md:my-2 md:ml-auto">
            <Link
              onClick={() => setNav(false)}
              to={"#send-request"}
              className="bg-pred md:bg-pgray2 text-center block w-full text-white text-xs px-6 py-3 md:shadow-2xl rounded-sm"
            >
              Get in touch
            </Link>
          </div>
        </nav>
        <div
          onClick={() => setNav(!nav)}
          className="ml-auto flex flex-col md:hidden -mb-1"
        >
          <span className="block w-6 h-1 bg-gray-700 mb-1" />
          <span className="block w-8 h-1 bg-gray-700 mb-1" />
          <span className="block w-4 h-1 bg-gray-700 mb-1" />
        </div>
      </div>
      {atv && (
        <div
          onMouseLeave={() => setNavAtv("")}
          className="w-full hidden md:block shadow-md border-t bg-white p-5 absolute top-0 mt-16"
        >
          <div className="container flex text-k3-black">
            <div
              className={`${!atv.split ? "w-2/3" : "flex-1"} flex space-x-8`}
            >
              <div className={`${atv.split ? "w-5/12" : "w-1/2"}`}>
                <img src={atv.image} alt="" />
                <p className="text-right text-small mt-2 text-pgray">
                  {atv.subtitle}
                </p>
              </div>
              <div className="text-base">
                <ul className="">
                  <li className="pb-4 uppercase text-pgray">{atv.title}</li>
                  <div
                    className={`grid row-gap-2  ${
                      atv.split && "grid-cols-2 col-gap-12 whitespace-no-wrap"
                    }`}
                  >
                    {atv.items.map(it => (
                      <li>
                        <Link
                          className="cursor-pointer white text-sm"
                          to={it.link || "/"}
                        >
                          {it.name}
                        </Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
            </div>
            <div className="ml-auto">
              <Link
                to={"#send-request"}
                onClick={() => setNavAtv("")}
                className="text-pred whitespace-no-wrap font-semibold border border-pred px-12 text-xs py-3 flex items-center"
              >
                SCHEDULE A MEETING{" "}
                <span className="text-xl -mt-1 ml-3">&rsaquo;</span>
              </Link>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}
