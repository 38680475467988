import React, {useState} from "react"
import axios from 'axios'

export const sendmail = (name, phone, email, message) => {
    return axios.post('https://comms.tcore.online/api/v1/generic/sendmail', {
        "to": "hello@touchcore.com.ng",
        "from": {
            "name": "TCORE",
            "email": "web@touchcore.com.ng"
        },
        "template_id": "d-db0d9899a43142bcb6e673d53233c88c",
        "substitutions": {
            "name": name,
            "phone": phone,
            "email": email,
            "message": message,
        }
    })
}

export const SendUsRequest = () => {
    const [showForm, setShowForm] = useState(false)
    const [loading, showLoading] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const toggleForm = () => setShowForm(!showForm)
    const submit = async (e) => {
        e.preventDefault()
        try {
            showLoading(true)
            const name = document.querySelector('input[name="name"]');
            const email = document.querySelector('input[name="email"]');
            const phone = document.querySelector('input[name="phone_no"]') ;
            const message = document.querySelector('textarea[name="message"]');
            const response = await sendmail(name.value, email.value, phone.value, message.value)
            if (response.data) {
                console.log(response.data)
                setSuccess(true)
                name.value = ''
                email.value = ''
                phone.value = ''
                message.value = ''
            }
        } catch (err) {
            setError(true)
            if (err.response) {
                console.log(err.response.data)
            }
        } finally {
            showLoading(false)
        }
    }
    return (
        <section id="send-request" className="bg-ppink">
            {showForm && (
                <section className="px-5 md:px-20 py-12">
                    <h4 className="text-white text-center my-10 mb-6 font-bold text-lg">
                        Send us a request
                    </h4>
                    {success && <p className={"text-green-500 text-base"}>Message sent successfully</p>}
                    {error && <p className={"text-red-500 text-base"}>An error occurred</p>}
                    <form
                        onSubmit={submit}
                    >
                        <div
                            className="grid grid-cols-1 md:grid-cols-2 col-gap-10 row-gap-4 lg:px-32 text-white text-sm">
                            <div className="flex  flex-col bg-white h-16 bg-opacity-50 p-1">
                                <label className="text-white font-semibold text-sm">
                                    Name<sup>*</sup>
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="name"
                                    className=" px-3 focus:border-pink-700 bg-transparent w-auto h-8 border-b-2 border-red-500"
                                />
                            </div>
                            <div className="flex   flex-col bg-white h-16 bg-opacity-50 p-1">
                                <label className="text-white font-semibold text-sm">
                                    Email<sup>*</sup>
                                </label>
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    className="px-3 focus:border-pink-700 bg-transparent w-auto h-8 border-b-2 border-red-500"
                                />
                            </div>
                            <div className="flex md:col-span-2  flex-col bg-white h-16 bg-opacity-50 p-1">
                                <label className="text-white font-semibold text-sm">
                                    Phone Number<sup>*</sup>
                                </label>
                                <input
                                    required
                                    type="tel"
                                    name="phone_no"
                                    className="px-3 focus:border-pink-700 bg-transparent w-auto h-8 border-b-2 border-red-500"
                                />
                            </div>

                            <div className="flex  flex-col bg-white bg-opacity-50 p-1 md:col-span-2">
                                <label className="text-white font-semibold text-sm">
                                    Message
                                </label>
                                <textarea
                                    name="message"
                                    className="p-3 focus:outline-none focus:border-pink-700 bg-transparent w-auto h-20 border-b-2 border-red-500"
                                />
                            </div>
                        </div>
                        <div className="flex justify-center my-6">
                            {!loading ? <button
                                    type="submit"
                                    className="shadow rounded-md px-12 py-3 text-red-700 bg-white font-black text-xl"
                                >
                                    Send
                                </button> :
                                <div className="loader"/>}
                        </div>
                    </form>
                </section>
            )}
            <section className="bg-pred2 md:flex justify-between items-center py-3 px-5 md:px-10 lg:px-20 ">
                <div data-aos="fade-right" className="text-left text-white">
                    <h6 className="uppercase text-sm font-medium mb-2">
                        Take your product to the next level
                    </h6>
                    <h6 className="font-black text-3xl leading-tight">
                        Talk to us about your project
                    </h6>
                </div>

                <button
                    data-aos="fade-left"
                    onClick={toggleForm}
                    className="explore-button mt-8 md:mt-0 md:mr-24"
                >
                    Schedule a call
                </button>
            </section>
        </section>
    )
}
