import React, {
  useEffect,
  useRef,
  useState
} from "react"

import { Link } from "gatsby"

import { usePost } from "../utils/insights"

const articles = [
  {
    image: require("./../assets/blog-image.png"),
    category: "Strategy",
    title: "Artistic growth is, more than it is anything else",
    short_content:
      "If the path is beautiful, let us not ask where it leads. f the path is beautiful, let us not ask where it leads.f the path is beautiful, let us not ask where it leads.f the path is beautiful, let us not ask where it leads. ",
  },
  {
    image: require("./../assets/blog-image.png"),

    category: "Digital Marketing",
    title: "Novelist with an unpronounceable name",
    short_content: "If the path is beautiful, let us not ask where it leads.",
  },
  {
    image: require("./../assets/blog-image.png"),
    category: "Web Development",
    title: "Like birds that fly across national borders",
    short_content: "If the path is beautiful, let us not ask where it leads.",
  },
  {
    image: require("./../assets/blog-image.png"),
    category: "Strategy",
    title: "Artistic growth is, more than it is anything else",
    short_content:
      "If the path is beautiful, let us not ask where it leads. f the path is beautiful, let us not ask where it leads.f the path is beautiful, let us not ask where it leads.f the path is beautiful, let us not ask where it leads. ",
  },
  {
    image: require("./../assets/blog-image.png"),

    category: "Digital Marketing",
    title: "Novelist with an unpronounceable name",
    short_content: "If the path is beautiful, let us not ask where it leads.",
  },
  {
    image: require("./../assets/blog-image.png"),
    category: "Web Development",
    title: "Like birds that fly across national borders",
    short_content: "If the path is beautiful, let us not ask where it leads.",
  },
]
function getMax(len, width) {
  let max = len - 2
  if (width < 768) {
    max = len
  } else if (width < 1024) {
    max = len - 1
  }
  return max
}
export function OurInsights() {
  const [current, setCurrent] = useState(0)
  const blogPosts = usePost({ type: "latest", limit: 6 })
  const [max, setMax] = useState(0)
  const articleRef = useRef(null)
  useEffect(() => {
    setMax(getMax(blogPosts.posts.length, window ? window.innerWidth : 1024))
  }, [current])
  useEffect(() => {
    if (articleRef.current) {
      let translateValue = 100 / 3
      if (window.innerWidth < 768) {
        translateValue = 100
      } else if (window.innerWidth < 1024) {
        translateValue = 100 / 2
      }
      articleRef.current.style.transform = `translateX(-${
        current * translateValue
      }%)`
    }
  }, [articleRef, current])
  const decrementCurrent = () =>
    setCurrent(current === 0 ? max - 1 : current - 1)
  const incrementCurrent = () =>
    setCurrent(current === max - 1 ? 0 : current + 1)
  return (
    <div className="py-12">
      <div className="flex container mb-5 px-5 md:px-3 ">
        <span className="w-16 md:w-32 bg-pred rounded-full h-1 mt-3 mr-2"></span>
        <h2 className="font-bold md:font-medium text-k2-gray text-lg md:text-4xl ">
          Our Insights
        </h2>
        <Link
          to="/our-insights"
          className="text-k2-gray flex items-baseline ml-auto text-base"
        >
          View all{" "}
          <span className="text-sm md:text-base text-pred arrow-style">
            &rarr;
          </span>
        </Link>
      </div>
      <div className=" w-full overflow-x-hidden container">
        <div
          ref={articleRef}
          className="transition-transform duration-1000 flex items-stretch w-full"
        >
          <Articles blogPosts={blogPosts} />
        </div>
      </div>
      {!!blogPosts.posts.length && (
        <div className="flex justify-center space-x-5 mt-8">
          <button
            onClick={decrementCurrent}
            className="border-2 border-gray-200 test-pred  px-2 h-12"
          >
            <span className="arrow-style">&larr;</span>
          </button>
          <button
            onClick={incrementCurrent}
            className="bg-pred text-white px-2 h-12"
          >
            <span className="arrow-style">&rarr;</span>
          </button>
        </div>
      )}
    </div>
  )
}

export const Articles = ({ blogPosts }) => {
  return (
    <>
      {!blogPosts.loading && !blogPosts.posts.length && (
        <div className="text-center py-16 w-full">No Insights found</div>
      )}
      {blogPosts.posts.map((article, i) => (
        <div
          key={"artic" + i}
          className="flex-shrink-0 w-full h-full md:w-1/2 px-3 pb-5 lg:w-1/3"
        >
          <div
            className={`blog-card-shadow hover:shadow-sm
                 bg-white text-black`}
          >
            <img
              src={blogPosts.image(article)}
              className="bg-gray-200 w-full h-40 object-cover"
            />
            <div className="p-5">
              <span className="inline-block whitespace-no-wrap text-small bg-pred text-white px-4 py-1">
                {article._embedded["wp:term"][0].map(v => v.name).join(", ")}
              </span>
              <h4 className="mt-3 mb-5 truncate">{article.title.rendered}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: article.excerpt.rendered,
                }}
                className="text-k-gray text-xs h-8 overflow-hidden"
              ></div>
              <Link
                to={`/our-insights?post=${article.id}`}
                className="mt-10 flex items-baseline py-2 uppercase text-xs font-semibold text-k2-gray"
              >
                Know More{" "}
                <span className="text-pred arrow-style font-black">&rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
