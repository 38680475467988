import Airtable from "airtable"

// const key = "keyc94gIhGGUJQidL"
// const base = "app069fWjQ3KzdHMs"
const airtableBase = new Airtable({ apiKey: "keyc94gIhGGUJQidL" }).base(
  "app069fWjQ3KzdHMs"
)
export async function fetchProjects() {
  const data = airtableBase("projects").select().all()
  return data
}

export async function fetchClients() {
  const data = airtableBase("clients").select().all()
  return data
}
